import React from 'react';
import './Footer.css'; // Link to the CSS file for styling

function Footer() {
  return (
    <footer className="game-footer">
      <div className="footer-icons">
        {/* Add your icons here; use <a> tags with <img> for icons */}
        <a href="https://twitter.com/luminarift"><img src="/icons/twitter.png" alt="Twitter"/></a>
        <a href="https://www.youtube.com/@luminarift"><img src="/icons/youtube.png" alt="YouTube"/></a>
        <a href="https://facebook.com/luminarift"><img src="/icons/facebook.png" alt="Facebook"/></a>
        <a href="https://www.instagram.com/luminarift"><img src="/icons/instagram.png" alt="Instagram"/>
        </a>
        {/* Add more icons as needed */}
      </div>
      <div className="footer-info">
        <p>| <a href="mailto:help@kwinkit.com">Contact Us</a> |</p>
        <p>© 2024 Lumina Rift. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
