import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />  // Remove React.StrictMode
);

// Optional: Hot reload code (can remove if not needed)
if (module.hot) {
  module.hot.accept();
}
