import React from 'react';
import './App.css';
import Footer from './Footer'; // Make sure the path to Footer.js is correct

const images = [
  '/lumina-rift+more.jpg',  // First image
  '/crystals.jpg',          // First separator with blur
  '/Emrald_Lyra_image.png', // Second image
  '/crystals.jpg',          // Second separator with blur
  '/Ivy_Ash_in_the_city_PSD.png', // Third image
];

function App() {
  return (
    <div>
      {images.map((image, index) => (
        <div key={index} className="background-image" style={{ backgroundImage: `url(${image})` }}>
          {/* Optional: Add any text or elements over the image here */}
        </div>
      ))}
      <Footer />  {/* Include the Footer component */}
    </div>
  );
}

export default App;
